import React, { useContext, useState, useEffect, useRef } from "react";
import { AccountContext } from "context/AccountContext";
import AddPaymentMethodForm from "components/ShopView/Stripe/AddPaymentMethodForm";
import Button from "components/Input/Button";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import Form from "components/LoginView/Input/Form";
import ShopService from "services/ShopService";
import LoadingContainer from "components/Loading/LoadingContainer";
import { useHistory } from "react-router";
import AccountService from "services/AccountService";

const REFRESH_TIMEOUT = 60000; // 1 minute

const FixPaymentView = (props) => {
  const history = useHistory();

  const { subscription } = useContext(AccountContext);

  const [paymentIntent, setPaymentIntent] = useState("");
  const [disabled, setDisabled] = useState(true);

  const ref = useRef();

  const refreshSubscriptionTimeout = useRef();
  const refreshSubscriptionAfter = (time) => {
    if (refreshSubscriptionTimeout.current) {
      clearTimeout(refreshSubscriptionTimeout.current);
    }

    refreshSubscriptionTimeout.current = setTimeout(() => {
      AccountService.refreshSubscription();
    }, time);
  };

  const loadPaymentIntent = async () => {
    const paymentIntent = await ShopService.getStripePaymentIntent();
    setPaymentIntent(paymentIntent?.payment_secret);
    setDisabled(false);
  };

  const mount = () => {
    loadPaymentIntent();

    return () => {
      if (refreshSubscriptionTimeout.current) {
        clearTimeout(refreshSubscriptionTimeout.current);
      }
    };
  };
  useEffect(mount, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (disabled) return;

    setDisabled(true);

    if (ref?.current) {
      const paymentInfo =
        ref.current?.beforeSubmit && (await ref.current.beforeSubmit());

      if (paymentInfo?.error || paymentInfo === false) {
        refreshSubscriptionAfter(REFRESH_TIMEOUT);
        return;
      }

      await ShopService.updateStripePaymentMethod(
        paymentInfo.payment_method,
        true
      );

      refreshSubscriptionAfter(REFRESH_TIMEOUT);
      return;
    }

    setDisabled(false);
  };

  useEffect(() => {
    if (refreshSubscriptionTimeout.current) {
      clearTimeout(refreshSubscriptionTimeout.current);
    }

    setDisabled((orig) => {
      if (paymentIntent && orig === true) {
        setTimeout(() => ref.current?.clear && ref.current.clear(), 0);
        return false;
      }

      return null;
    });
  }, [paymentIntent, subscription]);

  return (
    <Form onSubmit={handleSubmit}>
      {disabled && <LoadingContainer />}
      {(() => {
        switch (subscription?.payment_method) {
          case "stripe":
            return (
              <AddPaymentMethodForm
                ref={ref}
                disableLoadingSpinner={true}
                paymentIntent={paymentIntent}
              />
            );
          default:
            history.goBack();
            return null;
        }
      })()}
      <FlexButtonGroup justifyContent="flex-end">
        <Button disabled={disabled} type="submit">
          {"Update & Retry"}
        </Button>
      </FlexButtonGroup>
    </Form>
  );
};

export default FixPaymentView;
