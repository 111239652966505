import React, { useContext } from "react";
import StudentListView from "./StudentListView";
import styled from "styled-components";
import { compose } from "recompose";
import withTheme from "composers/withTheme";
import { Link, Redirect, useLocation } from "react-router-dom";

import { AccountContext } from "context/AccountContext";
import { AppContext } from "context/AppContext";

const Wrapper = compose(withTheme)(styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "56px" : "36px"};
  display: grid;
  grid-template-rows: auto auto 1fr;
  row-gap: ${({ responsiveSize }) =>
    responsiveSize === "desktop" ? "33px" : "20px"};
  justify-content: center;
  justify-items: center;
`);

const Home = (props) => {
  const { subscription } = useContext(AccountContext);

  const location = useLocation();
  const { style } = useContext(AppContext);

  return (
    <Wrapper>
      {(() => {
        if (style === "trial") {
          return <StudentListView {...props} />;
        } else if (subscription) {
          switch (subscription.status) {
            case "expired":
              return "expired";
            case "active":
              return <StudentListView {...props} />;
            case "pending_payment":
            case "failed":
            default:
              return <Redirect to={`/account/receipt`} />;
          }
        } else {
          // must purchase subscription
          return (
            <React.Fragment>
              <div>We hope you have enjoyed your free trial!</div>
              <Link
                to="/account/bundles"
                state={{ returnTo: location.pathname }}
              >
                Click Here to Subscribe
              </Link>
            </React.Fragment>
          );
        }
      })()}
    </Wrapper>
  );
};

export default Home;
