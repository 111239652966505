import React, { useState, useEffect, useRef } from "react";

import Button from "components/Input/Button";
import TextInput from "components/Input/TextInput";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const CouponInput = (props) => {
  const [coupon, setCoupon] = useState("");
  const [error, setError] = useState("");

  const handleChange = ({ target: { value } }) => {
    setCoupon(value);
  };

  const handleApplyCoupon = async (e) => {
    const value = coupon;
    const success = props.onApply && (await props.onApply(value));

    setError(success ? "" : "This is not a valid coupon code");

    props.onChange &&
      props.onChange({
        target: { name: props.name, value: success ? value : "" },
      });
  };

  // Clear the coupon if the bundle changes
  const prevBundleId = useRef();
  useEffect(() => {
    if (
      prevBundleId.current &&
      prevBundleId.current !== props.bundle?.product_id
    ) {
      setCoupon("");
    }
    prevBundleId.current = props.bundle?.product_id;
  }, [props.bundle]);

  return (
    <TextInput
      label="Coupon Code"
      value={coupon}
      placeholder="Coupon Code"
      name="coupon"
      onChange={handleChange}
      icon={<LocalOfferIcon />}
      error={error}
      errorMessage={error}
      inputProps={{
        endAdornment: (
          <Button onClick={handleApplyCoupon} disabled={!coupon}>
            Apply
          </Button>
        ),
      }}
    />
  );
};

export default CouponInput;
