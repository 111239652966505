import React, { useContext } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { compose } from "recompose";
import withTheme from "composers/withTheme";
import { useTheme } from "@material-ui/core/styles";

import Plan from "components/ShopView/Plan";
import Panel from "components/AccountView/Input/Panel";
import Button from "components/Input/Button";
import OptionButton from "components/Input/OptionButton";

import BlockIcon from "@material-ui/icons/Block";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import SchoolIcon from "@material-ui/icons/School";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import WarnIcon from "@material-ui/icons/Warning";

import { AccountContext } from "context/AccountContext";
import FlexButtonGroup from "components/Layout/FlexButtonGroup";
import { ReadableDate } from "helpers/DateFormatter";
import Balance from "components/AccountView/Balance";
import CardOverview from "components/ShopView/Stripe/CardOverview";

const Info = compose(withTheme)(styled.div`
  width: 100%;
  display: grid;
  ${({ responsiveSize }) =>
    responsiveSize === "phone" && "margin-bottom: 22px;"}
`);

const ManageSubscriptionView = (props) => {
  const { responsiveSize } = props;

  const { subscription } = useContext(AccountContext);

  const history = useHistory();
  const location = useLocation();

  const muiTheme = useTheme();

  const handleNewOrUpgrade = (e) => {
    history.push("/account/bundles", { returnTo: location.pathname });
  };

  const handleCancel = (e) => {
    history.push("/account/cancel");
  };

  const handleRenew = (e) => {
    history.push("/account/renew");
  };

  const handleUpdatePaymentMethod = (e) => {
    history.push("/account/update_payment");
  };

  const handleFixPaymentMethod = (e) => {
    history.push("/account/receipt");
  };

  const isPlanCancelled = subscription?.cancellation_date !== null;

  const planOptions = [
    {
      label: "Change Plan",
      onClick: handleNewOrUpgrade,
      icon: <SchoolIcon />,
      attributes: {
        disabled: !subscription || subscription.status !== "active",
      },
    },
  ];

  if (subscription?.payment_method === "stripe") {
    if (subscription.status === "failed_declined") {
      planOptions.push({
        label: "Fix Payment Method",
        onClick: handleFixPaymentMethod,
        icon: <CreditCardIcon />,
      });
    } else {
      planOptions.push({
        label: "Update Payment Method",
        onClick: handleUpdatePaymentMethod,
        icon: <CreditCardIcon />,
      });
    }
  }

  if (isPlanCancelled) {
    planOptions.push({
      label: "Renew Plan",
      onClick: handleRenew,
      icon: <AutorenewIcon />,
    });
  } else {
    planOptions.push({
      label: "Cancel Plan",
      onClick: handleCancel,
      icon: <BlockIcon style={{ color: "red" }} />,
      attributes: {
        style: {
          color: "red",
        },
      },
    });
  }

  return (
    <Panel invisible={responsiveSize === "phone" ? 1 : 0} style={props.style}>
      <div>
        <h3>Current Plan</h3>
      </div>
      <Info>
        {subscription ? (
          <React.Fragment>
            {location.state?.upgradeSuccess && (
              <div style={{ color: muiTheme.palette.primary.main }}>
                Your plan has been successfully changed.
              </div>
            )}
            <Plan
              bundle={subscription}
              current={true}
              buttonOverride={
                <table style={{ marginLeft: "auto" }}>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "right" }}>
                        <strong>Status</strong>
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {subscription.status}
                      </td>
                    </tr>
                    {isPlanCancelled ? (
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <strong>Cancellation Date</strong>
                        </td>
                        <td>{ReadableDate(subscription.cancellation_date)}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td style={{ textAlign: "right" }}>
                          <strong>Next Billing Date</strong>
                        </td>
                        <td>{ReadableDate(subscription.expiry_date)}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              }
            />
            <CardOverview title={<h3>Billing Information</h3>} />
            <FlexButtonGroup justifyContent="space-between">
              <div>
                <Balance />
              </div>
              <OptionButton options={planOptions}>
                {subscription?.status === "failed_declined" && (
                  <React.Fragment>
                    <WarnIcon />
                    &nbsp;
                  </React.Fragment>
                )}
                Plan Options
              </OptionButton>
            </FlexButtonGroup>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>No plan found</div>
            <Button onClick={handleNewOrUpgrade} variant="contained">
              Shop
            </Button>
          </React.Fragment>
        )}
      </Info>
    </Panel>
  );
};

export default compose(withTheme)(ManageSubscriptionView);
