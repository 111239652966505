import React, { useEffect, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { compose } from "recompose";
import styled from "styled-components";

import TusitawiLogo from "components/Logo/TusitawiLogo";
import withTheme from "composers/withTheme";

import LoginView from "./LoginView";
import PasswordRecovery from "./PasswordRecoveryView/PasswordRecoveryView";
import PromoView from "./PromoView";
import ShopView from "views/ShopView/ShopView";
import SignUpView from "./SignUpView";

import { AppContext } from "context/AppContext";

const SplitView = compose(withTheme)(styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" && `flex-direction: column-reverse;`}
  &>* {
    padding: 20px 0;
  }
`);

const InfoWrapper = compose(withTheme)(styled.div`
  position: relative;
  ${({ responsiveSize }) =>
    responsiveSize === "desktop"
      ? `
      width: 470px;
  `
      : `
      padding-top: 150px;
      padding-right: 30px;
      padding-left: 30px;
      text-align: center;
  `}
`);

const Info = compose(withTheme)(styled.div`
  ${({ responsiveSize }) =>
    responsiveSize === "desktop" &&
    `
      position: fixed;
      width: inherit;
      top: 50%;
      transform: translateY(-50%);
      padding-right: 30px;
      padding-left: 40px;
  `}
`);

const Contents = compose(withTheme)(styled.div`
  min-height: 100vh;
  flex-grow: 1;
  position: relative;
  background: linear-gradient(180deg, #318f4b 0%, #46b866 100%);

  display: grid;
  row-gap: 15px;
  grid-template-rows: min-content auto;
  justify-items: center;
  align-items: center;

  ${({ responsiveSize }) =>
    responsiveSize === "phone"
      ? `
    padding: 10px;
  `
      : `
    padding: 20px;
  `}
  ${({ responsiveSize }) =>
    responsiveSize !== "desktop" && `padding-bottom: 132px;`}
`);

export const LandingViewBody = compose(withTheme)((props) => {
  const { style, customizations } = useContext(AppContext);
  const { hide_sidebar } = customizations;

  const mount = () => {
    window.scroll({ top: 0 });
  };
  useEffect(mount, []);

  return (
    <SplitView>
      {!hide_sidebar && (
        <InfoWrapper>
          <Info>
            <PromoView />
          </Info>
        </InfoWrapper>
      )}
      <Contents>
        <TusitawiLogo rotated />
        <Switch>
          <Route path="/forgot-password" component={PasswordRecovery} />
          {style === "subscription" && (
            <Route
              path="/explore"
              render={(routerProps) => (
                <ShopView
                  {...routerProps}
                  after={SignUpView}
                  checkoutText="Sign Up"
                />
              )}
            />
          )}
          {style === "trial" && (
            <Route
              path="/signup"
              render={(routerProps) => <SignUpView {...routerProps} back="/" />}
            />
          )}
          <Route path="/" component={LoginView} />
        </Switch>
      </Contents>
    </SplitView>
  );
});

export default LandingViewBody;
