import React, { useState, useContext, useEffect } from "react";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import PhoneIcon from "@material-ui/icons/Phone";

import { AccountContext } from "context/AccountContext";

const Mpesa = (props, ref) => {
  const accountInfo = useContext(AccountContext);

  const [state, setState] = useState({
    phone: accountInfo?.profile?.phone_number || "",
  });

  const { onChange, disabled } = props;

  const handleChange = ({ target: { name, value } }) => {
    setState((orig) => ({ ...orig, [name]: value }));
  };

  const { name } = props;
  useEffect(() => {
    onChange && onChange({ target: { name, value: state } });
  }, [state, name, onChange]);

  return (
    <Field>
      <TextInput
        autoComplete="tel_national"
        icon={<PhoneIcon />}
        placeholder="Phone Number"
        type="tel"
        id="phone"
        name="phone"
        onChange={handleChange}
        value={state.phone}
        disabled={disabled}
      />
    </Field>
  );
};

export default React.forwardRef(Mpesa);
