import React, { useState, useEffect, useCallback, forwardRef } from "react";

import ShopService from "services/ShopService";

import { H2, H3 } from "components/LoginView/Headings";
import FormHelperText from "@material-ui/core/FormHelperText";

import LoadingContainer from "components/Loading/LoadingContainer";
import OrderOverview from "components/ShopView/OrderOverview";
import PaymentSelector from "../../components/ShopView/PaymentSelector";

const CheckoutView = (props, ref) => {
  const [state, setState] = useState({
    paymentOptions: [],
    paymentMethod: null,
    payment: {
      type: "",
      info: {},
    },
    preview: null,
  });

  const { name, bundle, upgrade, onChange, errors } = props;

  const loadPaymentOptions = async () => {
    const paymentOptions = await ShopService.getAvailablePaymentMethods();
    if (paymentOptions.length > 0) {
      setState((orig) => ({
        ...orig,
        paymentOptions,
      }));
    }
  };

  const loadUpgradePreview = useCallback(async () => {
    const preview = await ShopService.getUpgradePreview(bundle.product_id);
    setState((orig) => ({ ...orig, preview }));
  }, [bundle.product_id]);

  const loadDefaultPaymentMethod = async () => {
    const paymentMethod = await ShopService.getDefaultPaymentMethod();
    setState((orig) => ({ ...orig, paymentMethod }));
  };

  const mount = () => {
    loadPaymentOptions();
    if (upgrade) {
      loadUpgradePreview();
      loadDefaultPaymentMethod();
    }
  };
  useEffect(mount, []);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setState((orig) => ({ ...orig, [name]: value }));
    },
    [setState]
  );

  useEffect(() => {
    if (upgrade && bundle !== null) {
      setState((orig) => ({ ...orig, preview: null }));
      loadUpgradePreview();
    }
  }, [upgrade, bundle, loadUpgradePreview]);

  useEffect(() => {
    onChange &&
      onChange({
        target: {
          name,
          value: state.payment,
        },
      });
  }, [name, state.payment, onChange]);

  const loading =
    state.paymentOptions.length <= 0 ||
    (upgrade && (state.preview === null || state.paymentMethod === null));

  return (
    <React.Fragment>
      {loading && <LoadingContainer />}
      <H2>Payment Info</H2>
      <H3 style={{ textAlign: "left" }}>Billing information</H3>
      <PaymentSelector
        {...props}
        ref={ref}
        name="payment"
        onChange={handleChange}
        options={state.paymentOptions}
        paymentMethod={state.paymentMethod}
      />
      {errors && (
        <FormHelperText style={{ color: "red" }}>
          {errors.message}
        </FormHelperText>
      )}
      <OrderOverview {...props} bundle={upgrade ? state.preview : bundle} />
    </React.Fragment>
  );
};

export default forwardRef(CheckoutView);
