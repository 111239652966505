import React, { useContext } from "react";
import styled from "styled-components";

import { H3 } from "components/LoginView/Headings";
import { AppContext } from "context/AppContext";

const PriceTable = styled.table`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  border-collapse: collapse;

  & tr:last-child {
    border-top: 1px solid grey;
  }
`;

const PriceWarning = styled.div`
  position: relative;

  font-size: 12px;
  margin-bottom: 8px;

  padding-left: 10px;

  &:before {
    content: "*";
    position: absolute;
    left: 0;
  }
`;

const OrderOverview = (props) => {
  const { formatPrice } = useContext(AppContext);

  const { bundle, upgrade } = props;

  const { price, credit, coupon_discount, next_billing_date, adjusted_price } =
    bundle || {};

  return (
    <React.Fragment>
      {upgrade && (
        <React.Fragment>
          <H3 style={{ textAlign: "left" }}>What happens next</H3>
          <ul>
            <li>Your new plan starts today.</li>
            <li>
              Starting {next_billing_date}, you will be charged{" "}
              {formatPrice(price)}.
            </li>
            <li>
              We credit the value of your current plan to Your Credit. Your
              Credit is used for future payments.
            </li>
          </ul>
        </React.Fragment>
      )}
      <H3 style={{ textAlign: "left" }}>Order Summary</H3>
      <PriceTable>
        <tbody>
          <tr>
            <td>Your new plan</td>
            <td style={{ textAlign: "right" }}>{formatPrice(price)}</td>
          </tr>
          {!!credit && (
            <tr style={{ color: "green" }}>
              <td>Your Credit</td>
              <td style={{ textAlign: "right" }}>
                &ndash;{formatPrice(credit)}
              </td>
            </tr>
          )}
          {!!coupon_discount && (
            <tr style={{ color: "green" }}>
              <td>
                Coupon
                {coupon_discount.coupon
                  ? `: ${coupon_discount.coupon}`
                  : " Code"}
              </td>
              <td style={{ textAlign: "right" }}>
                &ndash;{formatPrice(coupon_discount.discount)}
              </td>
            </tr>
          )}
          <tr>
            <td>TOTAL</td>
            <td style={{ textAlign: "right" }}>
              {formatPrice(
                typeof adjusted_price === "undefined" ? price : adjusted_price
              )}
            </td>
          </tr>
        </tbody>
      </PriceTable>
      {adjusted_price > 0 && adjusted_price <= 100 ? (
        <PriceWarning>
          Totals less than Ksh100 are not charged until the next payment date.
        </PriceWarning>
      ) : null}
    </React.Fragment>
  );
};

export default OrderOverview;
