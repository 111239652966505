import React, { useState, useContext } from "react";
import styled from "styled-components";

import { AccountContext } from "context/AccountContext";
import DebugService from "services/DebugService";

const DebugWrapper = styled.div`
  position: fixed;

  text-align: right;

  bottom: 10px;
  right: 10px;
  z-index: 9999;

  background-color: white;

  ${({ open }) =>
    open &&
    `
    border-top: 1px solid black;
    border-left: 1px solid black;
  `}
`;
const DebugPanel = styled.div`
  & > table {
    margin: 0;
    width: 100%;
  }
`;
const Tabs = styled.div`
  display: flex;
`;
const Tab = styled.button`
  ${({ active }) => active && `font-weight: bold;`}
`;

const MenuButton = styled.button`
  margin-top: 5px;
`;

const EditSubscriptionRow = ({
  name,
  inputType = "text",
  options,
  subscription,
  onChange,
  hideUpdate = false,
}) => {
  const [value, setValue] = useState(subscription[name] || "");
  const handleChange = ({ target }) => {
    setValue(target.value);
    onChange && onChange({ target });
  };
  const handleUpdate = (e) => {
    DebugService.changeSubscription({ ...subscription, [name]: value });
  };
  const handleRevert = (e) => {
    setValue(subscription[name]);
    onChange && onChange({ target: { name, value: subscription[name] } });
  };
  return (
    <tr>
      <th>{name}:</th>
      <td>
        {(() => {
          switch (inputType) {
            case "dropdown":
              return (
                <select
                  style={{ width: "100%" }}
                  defaultValue={value}
                  name={name}
                  onChange={handleChange}
                >
                  {options.map((v, i) => (
                    <option value={v} key={i}>
                      {v}
                    </option>
                  ))}
                </select>
              );
            default:
              return (
                <input
                  style={{ width: "100%" }}
                  type={inputType}
                  name={name}
                  value={value}
                  onChange={handleChange}
                />
              );
          }
        })()}
      </td>
      {!hideUpdate && (
        <td>
          <button onClick={handleUpdate}>Update</button>
        </td>
      )}
      <td>
        <button onClick={handleRevert}>Revert</button>
      </td>
    </tr>
  );
};

const DebugMenu = (props) => {
  const { subscription, profile } = useContext(AccountContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const openMenu = () => {
    setMenuOpen((orig) => !orig);
  };

  const [subscriptionState, setSubscriptionState] = useState(subscription);
  const handleSubscriptionChange = ({ target }) => {
    const { name, value } = target;
    setSubscriptionState((orig) => ({ ...orig, [name]: value }));
  };
  const handleUpdate = (e) => {
    DebugService.changeSubscription(subscriptionState);
  };

  const handleDelete = (e) => {
    if (
      window.confirm(
        "Are you sure? This can't be undone. Page will refresh on completion."
      )
    ) {
      DebugService.deleteSubscription().then(() => window.location.reload());
    }
  };

  const tabs = {
    "View Subscription": subscription ? (
      <table>
        <tbody>
          {Object.keys(subscription).map((key, i) => (
            <tr key={i}>
              <th>{key}:</th>
              <td style={{ textAlign: "left" }}>{subscription[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div>No subscription</div>
    ),
    "View Profile": profile ? (
      <table>
        <tbody>
          {Object.keys(profile).map((key, i) => (
            <tr key={i}>
              <th>{key}:</th>
              <td style={{ textAlign: "left" }}>{profile[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div>Loading</div>
    ),
    "Edit Subscription": subscription ? (
      <React.Fragment>
        <table>
          <tbody>
            <EditSubscriptionRow
              name="students"
              inputType="number"
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
            <EditSubscriptionRow
              name="duration"
              inputType="number"
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
            <EditSubscriptionRow
              name="status"
              inputType="dropdown"
              options={["active", "pending_payment"]}
              subscription={subscription}
              onChange={handleSubscriptionChange}
              hideUpdate={true}
            />
          </tbody>
        </table>
        <div style={{ textAlign: "center" }}>
          <button onClick={handleUpdate}>Update</button>
          <button style={{ color: "red" }} onClick={handleDelete}>
            Delete
          </button>
        </div>
      </React.Fragment>
    ) : (
      <div>No Subscription</div>
    ),
  };
  const [currentTab, setCurrentTab] = useState(Object.keys(tabs)[0]);

  return (
    <DebugWrapper open={isMenuOpen}>
      {isMenuOpen && (
        <DebugPanel>
          <Tabs>
            {Object.keys(tabs).map((key, i) => (
              <Tab
                key={i}
                active={currentTab === key}
                onClick={() => setCurrentTab(key)}
              >
                {key}
              </Tab>
            ))}
          </Tabs>
          {tabs[currentTab]}
        </DebugPanel>
      )}
      <MenuButton onClick={openMenu}>&lt;&lt; Debug &gt;&gt;</MenuButton>
    </DebugWrapper>
  );
};

export default DebugMenu;
