import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from "@material-ui/core";

import RegionListView from "views/AdminView/SiteConfig/RegionListView";
import SettingsView from "views/AdminView/SiteConfig/SettingsView";
import Button from "components/Input/Button";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const SiteConfigView = (props) => {
  const [settingsExpanded, setSettingsExpanded] = useState(false);
  const [regionsExpanded, setRegionsExpanded] = useState(false);

  const toggleState = (setter) => setter((orig) => !orig);
  const toggleSettingsExpanded = () => toggleState(setSettingsExpanded);
  const toggleRegionsExpanded = () => toggleState(setRegionsExpanded);

  return (
    <React.Fragment>
      <Accordion
        variant="outlined"
        expanded={settingsExpanded}
        onChange={toggleSettingsExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Settings
        </AccordionSummary>
        <AccordionDetails>
          <SettingsView />
        </AccordionDetails>
        <AccordionActions>
          <Button color="secondary" onClick={() => setSettingsExpanded(false)}>
            Collapse
          </Button>
        </AccordionActions>
      </Accordion>
      <Accordion
        variant="outlined"
        expanded={regionsExpanded}
        onChange={toggleRegionsExpanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Region List
        </AccordionSummary>
        <AccordionDetails>
          <RegionListView />
        </AccordionDetails>
        <AccordionActions>
          <Button color="secondary" onClick={() => setRegionsExpanded(false)}>
            Collapse
          </Button>
        </AccordionActions>
      </Accordion>
    </React.Fragment>
  );
};

export default SiteConfigView;
