import FS from "./FetchService";

export default class ShopService {
  static async getBundles() {
    return await FS.get("/shop/bundles");
  }

  static async getUpgradePreview(product_id) {
    return await FS.get(`/shop/bundles/preview_upgrade/${product_id}`);
  }

  static async getAvailablePaymentMethods() {
    return await FS.get("/shop/pay");
  }

  static async getDefaultPaymentMethod() {
    return await FS.get("/shop/default_payment_method");
  }

  static async makePurchase(bundle, payment) {
    return await FS.post("/shop/pay", JSON.stringify({ bundle, payment }), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }

  static async purchaseUpgrade(bundle, payment) {
    return await FS.post("/shop/upgrade", JSON.stringify({ bundle, payment }), {
      header: (h) => h.append("Content-Type", "application/json"),
    });
  }

  static async applyCoupon(bundle, coupon, paymentType) {
    return await FS.post(
      "/shop/apply_coupon",
      JSON.stringify({ bundle, coupon, payment_type: paymentType }),
      {
        header: (h) => h.append("Content-Type", "application/json"),
      }
    );
  }

  static async getReceipt(order_id) {
    return await FS.get(`/receipts/${order_id}`);
  }

  // Stripe
  static async getStripeKey() {
    return await FS.get("/shop/stripe/pub_key");
  }

  static async getStripeSetupSecret() {
    return await FS.get("/shop/stripe/setup");
  }

  static async updateStripePaymentMethod(
    paymentMethodID,
    updateSubscription = false
  ) {
    const form = new FormData();
    form.append("payment_method_id", paymentMethodID);
    form.append("update_subscription", updateSubscription);

    return await FS.patch("/shop/stripe/payment_methods/update_default", form);
  }

  static async getStripePaymentIntent() {
    return await FS.get(`/shop/stripe/latest_payment_intent`);
  }

  static async getMyStripePaymentMethods() {
    return await FS.get("/shop/stripe/payment_methods");
  }

  static async cancelStripePlan() {
    return await FS.post("/shop/cancel");
  }

  static async renewStripePlan() {
    return await FS.post("/shop/renew");
  }
}
