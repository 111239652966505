import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Button from "components/Input/Button";
import Dropdown from "components/Input/Dropdown";
import TextInput from "components/Input/TextInput";
import Field from "components/Layout/Field";
import LoadingContainer from "components/Loading/LoadingContainer";
import Form from "components/LoginView/Input/Form";
import { push } from "connected-react-router";
import RegionService from "services/RegionService";
import LoginService from "services/LoginService";

import EmailIcon from "@material-ui/icons/Email";
import LocationIcon from "@material-ui/icons/LocationCity";
import LockIcon from "@material-ui/icons/Lock";
import PhoneIcon from "@material-ui/icons/Phone";
import { H2 } from "components/LoginView/Headings";
import { formatRequiredLabel } from "helpers/RequiredFields";
import { AppContext } from "context/AppContext";
import { AccountContext } from "context/AccountContext";
import BackArrow from "components/LoginView/Input/BackArrow";

const Error = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  font-size: 12px;
  width: 100%;
  color: white;
  position: absolute;
  top: 100%;
`;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const SignUp = (props) => {
  const { accountRequirements, style } = useContext(AppContext);
  const { setSessionToken } = useContext(AccountContext);

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: props.payment?.info.phone || "",
    region: "",
    password: "",
    password_confirmation: "",
    region_options: [],
    error: "",
    prevent_submit: true,
    loaded: [],
    fully_loaded: false,
  });

  const { onChange } = props;

  const require = ["regions"];
  const setLoaded = (value) => {
    setState((orig) => {
      const loaded = [...orig.loaded, value];
      const newState = { ...orig, loaded };

      if (require.every((item) => loaded.includes(item))) {
        newState.prevent_submit = false;
        newState.fully_loaded = true;
      }

      return newState;
    });
  };

  const loadRegions = async () => {
    try {
      const regions = await RegionService.getRegions();
      const region_options = regions.map(({ region_id, code, name }) => ({
        value: region_id,
        label: `${code ? `${code} - ` : ""}${name}`,
      }));
      setState((orig) => ({
        ...orig,
        region_options: region_options,
      }));
      setLoaded("regions");
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const mount = () => {
    loadRegions();

    return () => {};
  };
  useEffect(mount, []);

  const signUp = async () => {
    let redirecting = false;
    if (!state.prevent_submit) {
      setState((orig) => ({ ...orig, prevent_submit: true }));

      try {
        const result = await LoginService.signUp({
          first_name: state.first_name,
          last_name: state.last_name,
          email: state.email,
          phone_number: state.phone_number,
          region: state.region,
          password: state.password,
          password_confirmation: state.password_confirmation,
        });
        if (result) {
          if (!result.errors && result.access_token) {
            redirecting = true;
            setSessionToken(result.access_token);
          } else {
            setState((orig) => ({
              ...orig,
              error: { ...result.errors, global: result.message },
            }));
          }
        }
      } catch (e) {
        console.error("No data returned:", e.message);
      } finally {
        if (!redirecting) {
          setState((orig) => ({ ...orig, prevent_submit: false }));
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    signUp();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  useEffect(() => {
    onChange &&
      onChange({
        target: {
          name: props.name,
          value: {
            first_name: state.first_name,
            last_name: state.last_name,
            email: state.email,
            phone_number: state.phone_number,
            region: state.region,
            password: state.password,
            password_confirmation: state.password_confirmation,
          },
        },
      });
  }, [
    onChange,
    props.name,
    state.first_name,
    state.last_name,
    state.email,
    state.phone_number,
    state.region,
    state.password,
    state.password_confirmation,
  ]);
  const handleBack = (e) => {
    props.push(props.back);
  };

  // Determine which field is required and sort accordingly (required field appears first)
  const accountIdFields = [
    {
      state_value: "phone_number",
      label: "Phone Number",
      required: accountRequirements.required === "phone_number",
      options: {
        autoComplete: "tel_national",
        icon: <PhoneIcon />,
        type: "tel",
      },
    },
    {
      state_value: "email",
      label: "E-Mail",
      required: accountRequirements.required === "email",
      options: {
        autoComplete: "email",
        icon: <EmailIcon />,
        type: "email",
      },
    },
  ].sort(({ required: a }, { required: b }) => (a === b ? 0 : a ? -1 : 1));

  const isEmbedded = !!onChange;

  const error = state.error || props.errors || "";

  return (
    <React.Fragment>
      {!state.fully_loaded && <LoadingContainer />}
      {props.back && <BackArrow onClick={handleBack} />}
      <H2>{style === "trial" ? "Sign Up" : "Account Info"}</H2>
      <ConditionalWrapper
        condition={!isEmbedded}
        wrapper={(children) => <Form onSubmit={handleSubmit}>{children}</Form>}
      >
        <div>
          {accountIdFields.map(
            (
              {
                state_value,
                inputComponent: Comp = TextInput,
                label,
                required,
                options,
              },
              i
            ) => (
              <Field key={i}>
                <Comp
                  {...options}
                  placeholder={formatRequiredLabel(label, required)}
                  name={state_value}
                  id={state_value}
                  onChange={handleChange}
                  value={state[state_value]}
                  error={error[state_value]}
                  errorMessage={error[state_value]}
                />
              </Field>
            )
          )}
          <Field>
            <TextInput
              autoComplete="given_name"
              placeholder={formatRequiredLabel("First Name", true)}
              type="text"
              id="first_name"
              name="first_name"
              onChange={handleChange}
              value={state.first_name}
              error={error?.first_name}
              errorMessage={error?.first_name}
            />
          </Field>
          <Field>
            <TextInput
              autoComplete="family_name"
              placeholder={formatRequiredLabel("Last Name", true)}
              type="text"
              id="last_name"
              name="last_name"
              onChange={handleChange}
              value={state.last_name}
              error={error?.last_name}
              errorMessage={error?.last_name}
            />
          </Field>
          <Field>
            <Dropdown
              nullable
              nullValue={state.loaded ? "Unspecified" : "Loading..."}
              icon={<LocationIcon />}
              label={formatRequiredLabel("Region", false)}
              id="region"
              name="region"
              onChange={handleChange}
              value={state.region}
              options={state.region_options}
              error={error?.region}
              errorMessage={error?.region}
            />
          </Field>
          <Field>
            <TextInput
              autoComplete="new_password"
              icon={<LockIcon />}
              placeholder={formatRequiredLabel("Password", true)}
              type="password"
              id="password"
              name="password"
              onChange={handleChange}
              value={state.password}
              error={error?.password}
              errorMessage={error?.password}
            />
          </Field>
          <Field>
            <TextInput
              autoComplete="new_password_confirmation"
              icon={<LockIcon />}
              placeholder={formatRequiredLabel("Confirm Password", true)}
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              onChange={handleChange}
              value={state.password_confirmation}
              error={error?.password_confirmation}
              errorMessage={error?.password_confirmation}
            />
          </Field>
          {!isEmbedded && (
            <Field>
              <Button disabled={state.prevent_submit} type="submit">
                Register
              </Button>
            </Field>
          )}
        </div>
        {error && error.global && <Error>{error.global}</Error>}
      </ConditionalWrapper>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  push: (url) => dispatch(push(url)),
}))(SignUp);
