import React, { useContext } from "react";
import { AccountContext } from "context/AccountContext";
import AddPaymentMethodForm from "components/ShopView/Stripe/AddPaymentMethodForm";
import CardOverview from "./CardOverview";

const StripeForm = (props, ref) => {
  const { profile, subscription } = useContext(AccountContext);

  const { upgrade } = props;

  if (!profile || !subscription) {
    return <AddPaymentMethodForm {...props} ref={ref} />;
  }

  if (upgrade) {
    return <CardOverview {...props} />;
  }

  return null;
};

export default React.forwardRef(StripeForm);
