import React, { useState, useEffect, useCallback, useContext } from "react";

import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Mpesa from "./MpesaBillingForm";
import Stripe from "./Stripe/StripeForm";
import { AccountContext } from "context/AccountContext";
import Field from "components/Layout/Field";
import CouponInput from "./Stripe/CouponInput";

const paymentOptionForms = {
  mpesa: {
    label: "Mpesa",
    component: Mpesa,
  },
  stripe: {
    label: "Credit / Debit",
    component: Stripe,
  },
};

const PaymentSelector = (props, ref) => {
  const { subscription } = useContext(AccountContext);

  const { options = [], upgrade } = props;

  const [state, setState] = useState({
    type: "",
    info: {},
    coupon: "",
  });

  const handleTypeChange = ({ target: { name, value } }) =>
    setState((orig) => ({ ...orig, [name]: value, info: null }));

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setState((orig) => ({ ...orig, [name]: value }));
    },
    [setState]
  );

  useEffect(() => {
    if (
      upgrade &&
      subscription &&
      paymentOptionForms[subscription.payment_method]
    ) {
      setState((orig) => ({ ...orig, type: subscription.payment_method }));
    } else {
      setState((orig) => {
        if (!orig.type && options.length > 0) {
          return { ...orig, type: options[0] };
        } else {
          return orig;
        }
      });
    }
  }, [options, upgrade, subscription]);

  const { name, onChange } = props;
  useEffect(() => {
    onChange && onChange({ target: { name, value: state } });
  }, [state, name, onChange]);

  const PaymentForm =
    (state.type &&
      paymentOptionForms[state.type] &&
      paymentOptionForms[state.type].component) ||
    null;

  return (
    <React.Fragment>
      <em>Pay with </em>
      {upgrade || options.length === 1
        ? state.type && paymentOptionForms[state.type].label
        : options.length > 1 && (
            <RadioGroup
              row
              style={{ display: "inline" }}
              name="type"
              value={state.type}
              onChange={handleTypeChange}
            >
              {options.map((option, i) => (
                <FormControlLabel
                  key={i}
                  value={option}
                  control={<Radio color="primary" />}
                  label={paymentOptionForms[option].label}
                />
              ))}
            </RadioGroup>
          )}
      {PaymentForm && (
        <PaymentForm {...props} ref={ref} name="info" onChange={handleChange} />
      )}
      {!upgrade && (
        <Field>
          <CouponInput
            {...props}
            name="coupon"
            onChange={handleChange}
            value={state.coupon}
            onApply={props.onApplyCoupon}
          />
        </Field>
      )}
    </React.Fragment>
  );
};

export default React.forwardRef(PaymentSelector);
