import React, { useContext, useEffect, useState } from "react";
import { H2, H3 } from "components/LoginView/Headings";
import { AccountContext } from "context/AccountContext";
import OrderOverview from "../../../components/ShopView/OrderOverview";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import LoadingContainer from "components/Loading/LoadingContainer";
import ShopService from "services/ShopService";
import FixPaymentView from "./FixPaymentView";

const ReceiptView = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const { subscription } = useContext(AccountContext);
  const [receipt, setReceipt] = useState();

  const loadOrder = async (order_id) => {
    const order_receipt = await ShopService.getReceipt(order_id);
    setReceipt(order_receipt);
  };

  const mount = () => {
    const { order_id } = params;
    if (!order_id) {
      if (subscription) {
        history.replace(
          `${location.pathname}/${subscription.product_order_id}`
        );
      }
    } else if (subscription) {
      if (parseInt(subscription.product_order_id) === parseInt(order_id)) {
        setReceipt(subscription);
      } else {
        loadOrder(order_id);
      }
    }

    return () => {};
  };
  useEffect(mount, [subscription, params.order_id]);

  if (!receipt) return <LoadingContainer />;

  return (
    <React.Fragment>
      {(() => {
        switch (receipt.status) {
          case "pending_payment":
            return (
              <React.Fragment>
                <H2 style={{ fontWeight: "bold", color: "#35B158" }}>
                  Pending Payment
                </H2>
                <H3>Your purchase is being processed</H3>
              </React.Fragment>
            );
          case "active":
            return (
              <React.Fragment>
                <H2 style={{ fontWeight: "bold", color: "#35B158" }}>
                  <CheckCircleIcon /> Payment Complete
                </H2>
                <H3>
                  Thank you for your purchase!
                  <br />
                  Return to the <Link to="/">home page</Link> to begin learning!
                </H3>
              </React.Fragment>
            );
          case "failed":
          case "failed_declined":
            return (
              <React.Fragment>
                <H2 style={{ fontWeight: "bold", color: "red" }}>
                  <CancelIcon /> Payment Failed
                </H2>
                <H3>
                  Your card was declined.
                  <br />
                  Please enter a different card below.
                </H3>
                <FixPaymentView />
              </React.Fragment>
            );
          default:
        }
      })()}
      <H2 style={{ textAlign: "left", borderBottom: "1px solid black" }}>
        Order Receipt
      </H2>
      <OrderOverview bundle={receipt} highlightPlan={false} />
      <H3>Thank you for learning with Tusitawi!</H3>
    </React.Fragment>
  );
};

export default ReceiptView;
