import React, { useEffect, useState, useCallback } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

import AppService from "services/AppService";

export const AppContext = React.createContext();

export const AppProvider = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    loading: true,
  });

  const updateCustomizations = (customizations) => {
    setState((orig) => ({ ...orig, customizations }));
  };

  const updateLogos = (logos) => {
    setState((orig) => ({ ...orig, logos }));
  };

  const initializeGA = (ga) => {
    if (!module.hot) {
      ReactGA.initialize(ga);
      ReactGA.pageview(window.location.pathname + window.location.search);
      history.listen((location) => {
        ReactGA.pageview(location.pathname + location.search);
      });
    }
  };

  const loadSettings = async () => {
    const appSettings = await AppService.getAppSettings();

    const { ga, ...settings } = appSettings;

    if (ga) {
      initializeGA(ga);
    }

    setState(settings);
  };

  const mount = () => {
    loadSettings();
  };
  useEffect(mount, []);

  const formatPrice = useCallback(
    (price) => {
      if (!state.shopCurrency) return price;

      let parsedPrice = parseInt(price);
      if (isNaN(parsedPrice)) {
        parsedPrice = 0;
      }
      parsedPrice /= 100;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: state.shopCurrency,
      });
      return formatter.format(parsedPrice);
    },
    [state.shopCurrency]
  );

  if (state.loading) return null;

  return (
    <AppContext.Provider
      value={{ ...state, formatPrice, updateCustomizations, updateLogos }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
