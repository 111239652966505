import React, { useContext } from "react";

import { AccountContext } from "context/AccountContext";
import { Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { AppContext } from "context/AppContext";

const Balance = (props) => {
  const {
    profile: { balance },
  } = useContext(AccountContext);

  const { formatPrice } = useContext(AppContext);

  if (!balance) return "";

  const text =
    balance > 0
      ? {
          label: "Amount Owing",
          help: "This value will be added to your next invoice",
        }
      : {
          label: "Additional Credit",
          help: "This value will be subtracted from your next invoice",
        };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <strong>{text.label}:</strong>
      &nbsp;
      {formatPrice(Math.abs(balance))}
      <Tooltip
        arrow
        disableFocusListener
        title={<p style={{ fontSize: "16px" }}>{text.help}</p>}
      >
        <HelpOutline color="secondary" />
      </Tooltip>
    </div>
  );
};

export default Balance;
