import FS from "./FetchService";

export default class DebugService {
  static async changeSubscription(subscription) {
    const { students, duration, status } = subscription;
    return await FS.post(
      "/debug/subscription",
      JSON.stringify({ students, duration, status }),
      {
        header: (h) => h.append("Content-Type", "application/json"),
      }
    );
  }

  static async deleteSubscription() {
    return await FS.delete("/debug/subscription");
  }
}
