import React, { useContext } from "react";
import styled from "styled-components";
import Button from "components/Input/Button";
import { MaybePluralize } from "helpers/Pluralizer";
import { compose } from "recompose";
import withTheme from "composers/withTheme";
import { AppContext } from "context/AppContext";

const PlanCard = compose(withTheme)(styled.div`
  position: relative;
  width: 100%;

  margin: 14px 0px;
  ${({ responsiveSize }) =>
    responsiveSize === "phone"
      ? `
    padding: 14px 8px;
    `
      : `
    padding: 16px 12px;
    `}
  border: 0.2px solid #a0a0a0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: ${({ inverted }) => (inverted ? "white" : "black")};
  ${({ inverted }) => inverted && "background-color: #35b158;"}
  user-select: none;
  ${({ disabled }) => !disabled && `cursor: pointer;`};

  display: grid;
  grid-template-columns: 1fr 45%;
  grid-template-areas:
    "name button"
    "cost button"
    "period button";
  align-items: center;
`);

const PlanName = styled.span`
  grid-area: name;
  text-transform: uppercase;
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: bold;
`;
const Cost = compose(withTheme)(styled.span`
  grid-area: cost;
  font-size: ${({ responsiveSize }) =>
    responsiveSize === "phone" ? "28px" : "32px"};
  font-weight: bold;
  display: flex;
  flex-direction: column;
`);
const CostPeriod = styled.span`
  grid-area: period;
  font-weight: normal;
  font-size: 12px;
`;
const Right = styled.span`
  width: 100%;
  grid-area: button;
`;

const Plan = (props) => {
  const { formatPrice } = useContext(AppContext);
  const {
    bundle,
    hideButton,
    buttonOverride,
    variant,
    current: currentOverride,
    disabled: disabledOverride,
    onClick: _onClick,
  } = props;
  const { students, price, duration, current: bundleCurrent } = bundle;
  const current = currentOverride || bundleCurrent;
  const disabled = disabledOverride || current;
  const inverted = variant === "inverted";
  let periodText;
  switch (duration) {
    case 1:
      periodText = "Every Month";
      break;
    case 12:
      periodText = "Every Year";
      break;
    default:
      periodText = `Every ${duration} Months`;
  }
  const onClick = (e) => !disabled && _onClick && _onClick(e);

  return (
    <PlanCard disabled={disabled} onClick={onClick} inverted={inverted}>
      <PlanName>{`${students} ${MaybePluralize(
        "Student",
        students
      )}`}</PlanName>
      <Cost>{formatPrice(price)}</Cost>
      <CostPeriod>{periodText}</CostPeriod>
      <Right>
        {buttonOverride ||
          (!hideButton && (
            <Button
              style={{ height: "34px", width: "100%" }}
              disabled={disabled}
              onClick={onClick}
            >
              {current ? "Current Plan" : "Select"}
            </Button>
          ))}
      </Right>
    </PlanCard>
  );
};

export default Plan;
