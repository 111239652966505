import { H2, H3 } from "components/LoginView/Headings";
import { groupBy } from "lodash";
import React, { useState, useEffect, useContext } from "react";
import Plan from "components/ShopView/Plan";
import SubscriptionDurationSelector from "components/ShopView/SubscriptionDurationSelector";
import ShopService from "services/ShopService";
import LoadingContainer from "components/Loading/LoadingContainer";
import { AccountContext } from "context/AccountContext";

const ExploreBundlesView = (props) => {
  const { subscription } = useContext(AccountContext);

  const [{ loaded, bundles, duration }, setState] = useState({
    bundles: null,
    duration: 0,
    preventSubmit: true,
  });

  const { name, onSelect, upgrade } = props;

  const mount = () => {
    loadOptions();
    return () => {};
  };
  useEffect(mount, []);

  const loadOptions = async () => {
    try {
      const bundles = await ShopService.getBundles();

      const groupedBundles = groupBy(bundles, "duration");

      const defaultDuration =
        (upgrade && subscription && subscription.duration) ||
        Object.keys(groupedBundles)[0];

      setState((orig) => ({
        ...orig,
        bundles: groupedBundles,
        duration: "" + defaultDuration,
        preventSubmit: false,
        loaded: true,
      }));
    } catch (e) {
      console.error("No data returned:", e.message);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setState((orig) => ({ ...orig, [name]: value }));
  };

  if (!loaded) return <LoadingContainer />;
  return (
    <React.Fragment>
      <H2>{upgrade ? "Plans" : "Welcome!"}</H2>
      <H3>
        {upgrade
          ? "Select the plan that best suits your needs"
          : "Select a plan to start using Tusitawi"}
      </H3>
      <SubscriptionDurationSelector
        onChange={handleChange}
        options={Object.keys(bundles)}
        bundles={bundles}
        name="duration"
        value={duration}
      />
      {bundles[duration].map((bundle, i) => {
        return (
          <Plan
            key={i}
            bundle={bundle}
            current={bundle.product_id === subscription?.product_id}
            onClick={() =>
              onSelect({
                target: {
                  name: name,
                  value: bundle,
                },
              })
            }
          />
        );
      })}
    </React.Fragment>
  );
};

export default ExploreBundlesView;
